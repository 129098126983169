import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';

import { getAllProjects } from './projectsActions';

import './Projects.css';

const GetAll = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const projects = useSelector(state => state?.projectsData?.projects);
    const userId = useSelector(state => state?.loginData?.userId)

    useEffect(() => {
        dispatch(getAllProjects(userId));
    }, []);

    const handleProjectClick = id => {
        navigate(`/fe/projects/update/${id}`);
    }

    return (
        <>
        <h1 className="page-title">Projects</h1>
        <div className="get-all">
            {projects.map(project => {
                return (
                    <Paper className="project-pill" onClick={() => handleProjectClick(project?.id)}>
                        {project?.name}
                    </Paper>
                )
            })}
        </div>
        </>
  );
}

export default GetAll;
