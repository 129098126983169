import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import Close from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { getLargestUiOrder } from '../../helpers/getLargestUiOrder';

import {
    createProject,
    getProject,
    createSection,
    createTask,
    updateSection,
    updateTask,
    deleteSection,
    deleteTask,
    updateProject
} from './projectsActions';

import './Projects.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Update = () => {
    const dispatch = useDispatch();

    const { projectId } = useParams();
    const navigate = useNavigate();

    const imageRef = useRef();
    const fileRef = useRef();
    const taskFileRef = useRef();
    const taskVideoRef = useRef();

    const userId = useSelector(state => state?.loginData?.userId);
    const selectedProject = useSelector(state => state?.projectsData?.selectedProject);
    const sections = useSelector(state => state?.projectsData?.sections);

    const pendingProject = useSelector(state => state?.projectsData?.pendingProject);
    const getProjectsPending = useSelector(state => state?.projectsData?.getProjectsPending);
    const getProjectPending = useSelector(state => state?.projectsData?.getProjectPending);
    const getAllProjectDataPending = useSelector(state => state?.projectsData?.getAllProjectDataPending);
    const sectionPending = useSelector(state => state?.projectsData?.sectionPending);
    const taskPending = useSelector(state => state?.projectsData?.taskPending);

    const projectError = useSelector(state => state?.projectsData?.projectError);
    const getProjectsError = useSelector(state => state?.projectsData?.getProjectsError);
    const getProjectError = useSelector(state => state?.projectsData?.getProjectError);
    const getAllProjectDataError = useSelector(state => state?.projectsData?.getAllProjectDataError);
    const sectionError = useSelector(state => state?.projectsData?.sectionError);
    const taskError = useSelector(state => state?.projectsData?.taskError);

    const [selectedSection, setSelectedSection] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [showPhotoCheck, setShowPhotoCheck] = useState(false);
    const [showVideoCheck, setShowVideoCheck] = useState(false);

    const updateState = (obj, cb, newObj) => {
        cb({
            ...obj,
            ...newObj
        });
    }
    const [projectData, setProjectData] = useState({
        // user_id,
        // shareable,
        // name,
        // cover_photo,
        // budget,
        // status,
        // color,
        // duration,
        // ui_order,
        // imageObject,
        // adminTemplate
    })
    const [sectionData, setSectionData] = useState({
        // name,
        // ui_order,
        // project_id
    });
    const [taskData, setTaskData] = useState({
        // name,
        // description,
        // ui_order,
        // checked,
        // date,
        // time,
        // video,
        // photo,
        // section_id,
        // imageObject,
        // videoObject,
        // project_id,
        // user_id,
        // color
    });

    const [sectionModalIsOpen, setSectionModalIsOpen] = useState(false);
    const [taskModalIsOpen, setTaskModalIsOpen] = useState(false);

    useEffect(() => {
        dispatch(getProject(projectId));
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && (pendingProject || getProjectsPending || getProjectPending || getAllProjectDataPending || sectionPending || taskPending)) {
            setShowSpinner(true);
        } else {
            setShowSpinner(false);
        }
    }, [isMounted, pendingProject, getProjectsPending, getProjectPending, getAllProjectDataPending, sectionPending, taskPending])

    useEffect(() => {
        if (selectedProject) {
            setProjectData({
                ...projectData,
                name: selectedProject?.name || '',
                budget: selectedProject?.budget || 0,
                color: selectedProject?.color || '#E07E8B',
                shareable: Boolean(selectedProject?.shareable),
                adminTemplate: Boolean(selectedProject?.admin_template),
                imageObject: null
            });
        }
    }, [selectedProject]);

    useEffect(() => {
        if (!showErrorModal && (projectError || getProjectsError || getProjectError || getAllProjectDataError || sectionError || taskError)) {
            setShowErrorModal(true);
        }
    }, [projectError, getProjectsError, getProjectError, getAllProjectDataError, sectionError, taskError]);

    const circleColors = [
        '#E07E8B',
        '#C14C80',
        '#753581',
        '#2D3A64',
        '#DD4E40',
        '#F5D0EF',
        '#9CA4F9',
        '#9BE8A0',
        '#DADBDF'
    ];

    const handlePhotoUpload = () => {
        const file = fileRef.current.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = function(e) {
                const imagePreview = imageRef.current;
                imagePreview.src = e.target.result;
                const base64String = e.target.result.split(',')[1];
                updateState(projectData, setProjectData, { imageObject: {
                    imageBody: base64String,
                    imageExtension: file?.name?.slice(file?.name?.lastIndexOf('.') + 1)
                }});
            }

            reader.readAsDataURL(file);
        }
    }

    const handleTaskPhotoUpload = e => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = function(e) {
                const base64String = e.target.result.split(',')[1];
                updateState(taskData, setTaskData, {
                    videoObject: '',
                    video: '',
                    imageObject: {
                        imageBody: base64String,
                        imageExtension: file?.name?.slice(file?.name?.lastIndexOf('.') + 1)
                    }
                });
                setShowPhotoCheck(true);
                setShowVideoCheck(false);
            }

            reader.readAsDataURL(file);
        }
    }

    const handleTaskVideoUpload = e => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = function(e) {
                const base64String = e.target.result.split(',')[1];
                updateState(taskData, setTaskData, {
                    imageObject: '',
                    photo: '',
                    videoObject: {
                        imageBody: base64String,
                        imageExtension: file?.name?.slice(file?.name?.lastIndexOf('.') + 1)
                    }
                })
                setShowPhotoCheck(false);
                setShowVideoCheck(true);
            }

            reader.readAsDataURL(file);
        }
    }

    const handleSectionSave = () => {
        if (sectionData?.id) {
            dispatch(updateSection(sectionData));
        } else {
            dispatch(createSection(sectionData));
        }
        setSectionModalIsOpen(false);
    }

    const handleDeleteSection = sectionId => {
        dispatch(deleteSection(sectionId));
    }

    const handleDeleteTask = section => {
        dispatch(deleteTask(section));
    }

    const handleTaskSave = () => {
        if (taskData?.id) {
            dispatch(updateTask(taskData));
        } else {
            dispatch(createTask({
                ...taskData,
                ui_order: taskData?.ui_order ?? (getLargestUiOrder(selectedSection?.tasks || []) || selectedSection?.tasks?.length || 0),
                checked: false,
                project_id: selectedProject?.id,
                user_id: selectedProject?.user_id,
                color: selectedProject?.color
            }));
        }
        setTaskModalIsOpen(false);
    }

    const handleSectionEdit = section => {
        setSectionData({
            ...sectionData,
            ...section
        });
        setSectionModalIsOpen(true);
    }

    const handleTaskEdit = task => {
        setTaskData({
            ...taskData,
            ...task
        });
        setTaskModalIsOpen(true);
    }

    const handleProjectSave = () => {
        dispatch(updateProject({ ...projectData, id: projectId }, navigate));
    }

    const handleAddPhoto = () => {
        fileRef.current.click();
    }

    const handleAddTaskPhoto = () => {
        taskFileRef.current.click()
    }

    const handleAddTaskVideo = () => {
        taskVideoRef.current.click()
    }

    const hasPhoto = taskData?.imageObject?.imageBody || taskData?.photo;
    const hasVideo = taskData?.videoObject?.imageBody || taskData?.video;

    return (
        <div className="create">
            <section className={`load-screen cover ${showSpinner ? 'show' : ''}`.trim()}>
                <div className="spinner" />
            </section>
            <h1 className="page-title" style={{ marginLeft: 0 }}>Edit Project</h1>
            <TextField
                label="Title"
                placeholder=""
                value={projectData?.name || ''}
                type="text"
                onChange={e => updateState(projectData, setProjectData, { name: e.target.value })}
                variant="standard"
                sx={{ mb: 3, width: '100%' }}
            />
            <TextField
                label="Budget"
                placeholder=""
                value={projectData?.budget || ''}
                type="number"
                onChange={e => updateState(projectData, setProjectData, { budget: e.target.value })}
                variant="standard"
                sx={{ mb: 3, width: '100%' }}
            />
            <Box sx={{ maxWidth: '100%' }}>
                <img ref={imageRef} style={{ marginTop: '24px', marginBottom: '12px' }} src={selectedProject?.cover_photo} />
                <div onClick={handleAddPhoto} className="add-media">
                    <AddAPhotoIcon />&nbsp;&nbsp;Add/Edit project cover photo
                </div>
                <input hidden ref={fileRef} type="file" onChange={handlePhotoUpload} />
            </Box>
            <Divider sx={{ width: '100%', mb: 3, mt: 1 }} />
            Update color
            <Box
                sx={{ display: 'flex' }}
                className="circles"
            >
                {circleColors.map((c, i) => <div key={i} onClick={() => updateState(projectData, setProjectData, { color:  c })} className="color-circle" style={{ background: c, border: c === projectData?.color ? '4px solid #000' : ''  }} />)}
            </Box>
            <Divider sx={{ width: '100%', mb: 3, mt: 1 }} />
            <FormControlLabel
                label="Shareable"
                control={
                    <Checkbox
                        type="checkbox"
                        checked={Boolean(projectData?.shareable)}
                        onChange={e => updateState(projectData, setProjectData, { shareable: e.target.checked })}
                    />
                }
            />
            <Divider sx={{ width: '100%', mb: 3, mt: 0 }} />
            <FormControlLabel
                label="Admin Template"
                control={
                    <Checkbox
                        type="checkbox"
                        checked={Boolean(projectData?.adminTemplate)}
                        onChange={e => updateState(projectData, setProjectData, { adminTemplate: e.target.checked })}
                    />
                }
            />
            <Divider sx={{ width: '100%', mb: 3, mt: 0 }} />
            <section className="sections">
                {sections?.map((section, i) => {
                    return (
                        <div key={`${section.name}${section.id}${i}`}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                                <p>{section.name}</p>
                                <Box sx={{ display: 'flex', width: '80px', justifyContent: 'space-between' }}>
                                    <div onClick={() => handleDeleteSection(section?.id)}><DeleteIcon /></div>
                                    <div onClick={() => handleSectionEdit(section)}><EditIcon /></div>
                                </Box>
                            </Box>
                            {section?.tasks?.map((task, index) => (
                                <div className="task" key={`${task.name}${task.id}${index}`}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                                        <p>{task.name}</p>
                                        <Box sx={{ display: 'flex', width: '80px', justifyContent: 'space-between' }}>
                                            <div onClick={() => handleDeleteTask(task)}><DeleteIcon /></div>
                                            <div onClick={() => handleTaskEdit(task)}><EditIcon /></div>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <p>{task.description}</p>
                                    </Box>
                                    {task.video &&
                                        <Box sx={{ mb: 3 }}>
                                            <video width="100%" controls>
                                                <source src={task.video} type="video/mp4" />
                                            </video>
                                        </Box>
                                    }
                                    {task.photo && !task.video &&
                                        <Box sx={{ mb: 3 }}>
                                            <img src={task.photo} />
                                        </Box>
                                    }
                                </div>
                            ))}
                            <Button variant="outlined" sx={{ mb: 3, display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                <div
                                    onClick={() => {
                                        setTaskModalIsOpen(true);
                                        updateState(taskData, setTaskData, { section_id: section?.id });
                                        setSelectedSection(section);
                                    }}
                                    style={{ marginRight: '8px' }}
                                >
                                    Create task
                                </div>
                                <AddIcon />
                            </Button>
                            <Divider sx={{ mb: 3 }} />
                        </div>
                    )
                })}
                <Button variant="outlined" sx={{ mb: 3, display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                    <div onClick={() => setSectionModalIsOpen(true)} style={{ marginRight: '32px' }}>Create section</div><AddIcon />
                </Button>
            </section>
            <Modal
                isOpen={sectionModalIsOpen}
                onAfterOpen={() => {}}
                onAfterClose={() => {
                    setSectionModalIsOpen(false);
                    setSectionData({});
                }}
                className="my-modal"
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        zIndex: 1
                    }
                }}
            >
                <div className="close-modal" onClick={() => {
                    setSectionModalIsOpen(false);
                    setSectionData({});
                }}>
                    <Close />
                </div>
                <TextField
                    label="Section name"
                    placeholder=""
                    value={sectionData?.name}
                    onChange={e => updateState(sectionData, setSectionData, { name: e.target.value })}
                    variant="standard"
                    sx={{ mb: 3 }}
                />
                <Button variant="contained" onClick={handleSectionSave}>
                    Save
                </Button>
            </Modal>
            <Modal
                isOpen={taskModalIsOpen}
                onAfterOpen={() => {}}
                onAfterClose={() => {
                    setTaskModalIsOpen(false);
                    setTaskData({});
                    setShowPhotoCheck(false);
                    setShowVideoCheck(false);
                }}
                className="my-modal"
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        zIndex: 1
                    }
                }}
            >
                <div className="close-modal" onClick={() => {
                    setTaskModalIsOpen(false);
                    setTaskData({});
                }}>
                    <Close />
                </div>
                <TextField
                    label="Task name"
                    placeholder=""
                    value={taskData?.name || ''}
                    onChange={e => updateState(taskData, setTaskData, { name: e.target.value })}
                    variant="standard"
                    sx={{ mb: 3 }}
                />
                <TextField
                    multiline
                    label="Task description"
                    placeholder=""
                    value={taskData?.description || ''}
                    onChange={e => updateState(taskData, setTaskData, { description: e.target.value })}
                    variant="standard"
                    sx={{ mb: 3 }}
                />
                <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div onClick={handleAddTaskPhoto} className="add-media">
                        <AddAPhotoIcon />&nbsp;&nbsp;<span>Add/Edit task photo</span>
                        {showPhotoCheck && <CheckIcon />}
                    </div>
                    {hasPhoto &&
                        <div onClick={() => {
                            updateState(taskData, setTaskData, {
                                imageObject: null,
                                photo: ''
                            });
                        }}>
                            <DeleteIcon />
                        </div>
                    }
                    <input ref={taskFileRef} hidden type="file" onChange={handleTaskPhotoUpload} />
                </Box>
                -OR-
                <Box sx={{ mb: 3, mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div onClick={handleAddTaskVideo} className="add-media">
                        <VideoCameraBackIcon />&nbsp;&nbsp;<span>Add/Edit task video (MP4 format)</span>
                        {showVideoCheck && <CheckIcon />}
                    </div>
                    {hasVideo &&
                        <div onClick={() => {
                            updateState(taskData, setTaskData, {
                                videoObject: '',
                                video: ''
                            });
                        }}>
                            <DeleteIcon />
                        </div>
                    }
                    <input ref={taskVideoRef} hidden type="file" onChange={handleTaskVideoUpload} />
                </Box>
                <Button variant="contained" onClick={handleTaskSave}>
                    Save
                </Button>
            </Modal>
            <Modal
                isOpen={showErrorModal}
                onAfterOpen={() => {}}
                onAfterClose={() => {
                    setShowErrorModal(false);
                }}
                className="my-modal"
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        zIndex: 1
                    }
                }}
            >
                <div className="close-modal" onClick={() => {
                    setShowErrorModal(false);
                }}>
                    <Close />
                </div>
                There was an error processing your request. Please try again later, or call Scott :)
                <Button variant="contained" onClick={() => setShowErrorModal(false)}>
                    Okay
                </Button>
            </Modal>
            <Button variant="contained" sx={{ alignSelf: 'flex-end' }} onClick={handleProjectSave}>SAVE</Button>
        </div>
  );
}

export default Update;
