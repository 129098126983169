import { useEffect, useState } from 'react';

import './css/animate.css';
import './css/bootstrap.min.css';
import './css/glightbox.min.css';
import './css/LineIcons.2.0.css';
import './css/main.css';
import './css/tiny-slider.css';

export default function Home() {
    const [hasLoaded, updateHasLoaded] = useState(false);

    const appendScript = (scriptToAppend, afterLoad) => {
        const script = document.createElement("script");
        script.src = scriptToAppend;
        script.async = true;
        script.onload = () => {
            if (window.fadeout) {
                setTimeout(() => {
                    window.fadeout();
                }, 500);
            }
        }
        document.body.appendChild(script);
    }

    useEffect(() => {
        appendScript(`${process.env.PUBLIC_URL}/assets/js/bootstrap.min.js`);
        appendScript(`${process.env.PUBLIC_URL}/assets/js/wow.min.js`);
        appendScript(`${process.env.PUBLIC_URL}/assets/js/tiny-slider.js`);
        appendScript(`${process.env.PUBLIC_URL}/assets/js/glightbox.min.js`);
        appendScript(`${process.env.PUBLIC_URL}/assets/js/count-up.min.js`);
        appendScript(`${process.env.PUBLIC_URL}/assets/js/main.js`, true);
        // appendScript(`${process.env.PUBLIC_URL}/assets/js/init.js`);
    }, []);

  return (
      <body>
      <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
      </div>
      {/* Start Header Area */}
      <header className="header navbar-area">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-lg-12">
                      <div className="nav-inner">
                          {/* Start Navbar */}
                          <nav className="navbar navbar-expand-lg">
                              <a className="navbar-brand" href="#home" style={{ padding: '4px', background: '#fff', borderRadius: '4px' }}>
                                  <img src="./assets/images/logo/logo.png" alt="Logo" style={{ width: '45px', height: '45px' }}/>
                              </a>
                              <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                  aria-expanded="false" aria-label="Toggle navigation">
                                  <span className="toggler-icon"></span>
                                  <span className="toggler-icon"></span>
                                  <span className="toggler-icon"></span>
                              </button>
                              <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                  <ul id="nav" className="navbar-nav ms-auto">
                                      <li className="nav-item">
                                          <a href="#home" className="page-scroll active"
                                              aria-label="Toggle navigation">Home</a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#features" className="page-scroll"
                                              aria-label="Toggle navigation">Features</a>
                                      </li>

                                      <li className="nav-item">
                                          <a href="#guides" className="page-scroll"
                                              aria-label="Toggle navigation">Guides</a>
                                      </li>
                                      <li className="nav-item">
                                          <a href="#contact" aria-label="Toggle navigation">Contact</a>
                                      </li>
                                      {/*
                                          <li className="nav-item">
                                              <a className="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                                                  data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent"
                                                  aria-expanded="false" aria-label="Toggle navigation">Blog</a>
                                              <ul className="sub-menu collapse" id="submenu-1-4">
                                                  <li className="nav-item"><a href="javascript:void(0)">Blog Grid Sidebar</a>
                                                  </li>
                                                  <li className="nav-item"><a href="javascript:void(0)">Blog Single</a></li>
                                                  <li className="nav-item"><a href="javascript:void(0)">Blog Single
                                                          Sibebar</a></li>
                                              </ul>
                                          </li>
                                        */}

                                  </ul>
                              </div> {/* navbar collapse */}
                              <div className="button add-list-button">
                                  <a href="#guides" className="btn">Explore App</a>
                              </div>
                          </nav>
                          {/* End Navbar */}
                      </div>
                  </div>
              </div> {/* row */}
          </div> {/* container */}
      </header>
      {/* End Header Area */}

      {/* Start Hero Area */}
      <section id="home" className="hero-area">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-lg-5 col-md-12 col-12">
                      <div className="hero-content">
                          <h1 className="wow fadeInLeft" data-wow-delay=".4s">Stop Waiting and Start Creating with RENOVATE!</h1>
                          <p className="wow fadeInLeft" data-wow-delay=".6s">Welcome to Renovate, your one-stop shop for all things DIY and home</p>
                          {/*
                              <div className="button wow fadeInLeft" data-wow-delay=".8s">
                                  <a href="javascript:void(0)" className="btn"><i className="lni lni-apple"></i> App Store</a>
                                  <a href="javascript:void(0)" className="btn btn-alt"><i className="lni lni-play-store"></i> Google
                                      Play</a>
                              </div>
                          */}
                      </div>
                  </div>
                  <div className="col-lg-7 col-md-12 col-12">
                      <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
                          <img src="./assets/images/hero/startProject.png" alt="#" style={{ maxWidth: '220px', borderRadius: '16px' }} />
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* End Hero Area */}

      {/* Start Features Area */}
      <section id="features" className="features section">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="section-title">
                          <h3 className="wow zoomIn" data-wow-delay=".2s">Features</h3>
                          <h2 className="wow fadeInUp" data-wow-delay=".4s">All Your DIY Needs In One App
                          </h2>
                          <p className="wow fadeInUp" data-wow-delay=".6s">Checkout some of the features we offer.</p>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                      {/* Start Single Feature */}
                      <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                          <i className="lni lni-cloud-upload"></i>
                          <h3>Templates</h3>
                          <p>Pre-populated templates written by Christine that are step by step (customizable) including photo/video and written instructions as well as material shopping list that links straight over to the products!  Once the app is live, this section will continue to grow with more templates.</p>
                      </div>
                      {/* End Single Feature */}
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                      {/* Start Single Feature */}
                      <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                          <i className="lni lni-lock"></i>
                          <h3>New Projects</h3>
                          <p>Start from scratch with a new project. Map out your own projects steps (assign them to a specific calendar date to plan out your week/month) and keep track of your expenses, budget, materials, mood board and more!</p>
                      </div>
                      {/* End Single Feature */}
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                      {/* Start Single Feature */}
                      <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                          <i className="lni lni-reload"></i>
                          <h3>Resources</h3>
                          <p>Be sure to check out the resources tab to help calculate cuts and spacing for those wood projects</p>
                      </div>
                      {/* End Single Feature */}
                  </div>
                  {/*

                      <div className="col-lg-4 col-md-6 col-12">
                          <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                              <i className="lni lni-shield"></i>
                              <h3>Advanced Security</h3>
                              <p>It is a long established fact that a reader will be distracted by the readable content of a
                                  page at its layout.</p>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                          <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                              <i className="lni lni-cog"></i>
                              <h3>Powerful API</h3>
                              <p>It is a long established fact that a reader will be distracted by the readable content of a
                                  page at its layout.</p>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                          <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                              <i className="lni lni-layers"></i>
                              <h3>Database Backups</h3>
                              <p>It is a long established fact that a reader will be distracted by the readable content of a
                                  page at its layout.</p>
                          </div>
                      </div>
                    */}

              </div>
          </div>
      </section>
      {/* End Features Area */}

      {/* Start Achievement Area */}
      <section className="our-achievement section">
          <div className="container">
              <div className="row">
                  <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
                      <div className="title">
                          <h2>We are here to help</h2>
                          <p>We provide an ever growing list of resources and templates to help you tackle all sorts of projects</p>
                      </div>
                  </div>
              </div>
              {/*
                  <div className="row">
                      <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                          <div className="row">
                              <div className="col-lg-4 col-md-4 col-12">
                                  <div className="single-achievement wow fadeInUp" data-wow-delay=".2s">
                                      <h3 className="counter"><span id="secondo1" className="countup" cup-end="100">100</span>%</h3>
                                      <p>satisfaction</p>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12">
                                  <div className="single-achievement wow fadeInUp" data-wow-delay=".4s">
                                      <h3 className="counter"><span id="secondo2" className="countup" cup-end="120">120</span>K</h3>
                                      <p>Happy Users</p>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12">
                                  <div className="single-achievement wow fadeInUp" data-wow-delay=".6s">
                                      <h3 className="counter"><span id="secondo3" className="countup" cup-end="125">125</span>k+</h3>
                                      <p>Downloads</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
            */}

          </div>
      </section>
      {/* End Achievement Area */}

      {/* Start Pricing Table Area */}
      {/*
         */}
      <section id="guides" className="pricing-table section">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="section-title">
                          <h3 className="wow zoomIn" data-wow-delay=".2s">Guides</h3>
                          <h2 className="wow fadeInUp" data-wow-delay=".4s">User Guides</h2>
                          <p className="wow fadeInUp" data-wow-delay=".6s">Below are some guides to help you navigate the app. The guides are also available in the app itself in the help section within your account page.</p>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Welcome to Renovate!</h4>
                              <img src="./assets/images/startProject.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>When you first load the app, you will be able to create a new project or you can pick from a list of templates.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">New Project</h4>
                              <img src="./assets/images/newProject.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>When creating a project, you MUST provide a name, budget, and color. You can also add an image if you would like. Then press "Done" to save.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Home View</h4>
                              <img src="./assets/images/homeView.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>On the main page you will see your latest 2 projects. You can press "See All", to see all your projects. You can update the project status by pressing the status.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Upcoming Tasks</h4>
                              <img src="./assets/images/upcomingTasks.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>You will also see Upcoming Tasks. These are tasks that you have assigned a start or end date. We will show up to 5 tasks on the home page ordered by the task closest to today's date. Press 'See All', to see all Upcoming Tasks.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Resources</h4>
                              <img src="./assets/images/resources.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>We have provided some helpful resources with more to come in the future. These include other websites that are useful, calculators, etc... Press "See All", to see all the resources. Press the pin icon to pin the resource to your home page for quick access.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Welcome to your project!</h4>
                              <img src="./assets/images/projectOverview.jpeg" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>From here, you can see your budget, edit tasks, expenses, materials, and your moodboard. To edit or delete your project, press the three dots in the upper right corner.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Budget</h4>
                              <img src="./assets/images/budget.jpeg" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>Press the budget icon to see your budget details. Editing expenses will update your budget details.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Sections</h4>
                              <img src="./assets/images/section.jpeg" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>To edit a section title, press the title section itself and input your title. You can delete the entire section by pressing the garbage icon.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Task Name</h4>
                              <img src="./assets/images/taskText.jpeg" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>To edit a task name, you can press the task name itself and input your changes, or you can press the chevron to open the task modal and update the name there.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Move a Task</h4>
                              <img src="./assets/images/taskMoving.jpeg" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>To move a task, long press the task and move it any where within the task section.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Complete a Task</h4>
                              <img src="./assets/images/taskComplete.jpeg" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>To mark a task a complete, check the checkbox and we will celebrate with you! Uncheck the task to mark it as incomplete.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Task Details</h4>
                              <img src="./assets/images/taskModal.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>You can see all the task details by pressing the chevron icon on the right side of the task. This will allow you to see and edit the task name, details, start and end dates, and image. Press "Done", to save your changes. You can also delete your task from here.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Task Calendar</h4>
                              <img src="./assets/images/calendar.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>If you add a start or end date to a task, you will be able to see the tasks on the Calendar page. The tasks will also get added to upcoming tasks.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Expenses</h4>
                              <img src="./assets/images/expenses.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>Here you can see a list of all your expenses organized by types. When adding an expense you MUST add a name and price. You can also add a category, receipt, and notes. Press "Done" to save.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Expense Details</h4>
                              <img src="./assets/images/expensesModal.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>To edit expense details, press the expense. Within expense details you can edit name, price, category, receipt, and notes. Press "Done" to save.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Materials and Tools</h4>
                              <img src="./assets/images/materials.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>This will show all your materials and tools along with any links associated with them. You can add new materials and tools by pressing the "Add item to", sections.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Materials and Tools Details</h4>
                              <img src="./assets/images/materialsEdit.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>To edit a material just press the material or tool and input your changes. You can update the link or delete the material or tool by pressing the link or delete icons.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">New Moodboard</h4>
                              <img src="./assets/images/moodboardNoImages.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>You can add photos to your moodboard by pressing the plus icon.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Add Photo</h4>
                              <img src="./assets/images/moodboardAdd.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>When adding a photo, you can choose from your photos or your camera.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Organize Moodboard</h4>
                              <img src="./assets/images/moodboardRotate.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>When a photo is added, it will show in the upper left corner. Images will overlap each other if not moved.</p>
                          </div>
                          <div className="table-content">
                              <h4 className="middle-title">How to Organize</h4>
                              <ul className="table-list">
                                  <li><i className="lni lni-checkmark-circle"></i> Move photo by dragging it</li>
                                  <li><i className="lni lni-checkmark-circle"></i> Rotate photo by using two fingers to turn it</li>
                                  <li><i className="lni lni-checkmark-circle"></i> Change photo size by using two fingers to pinch in and out</li>
                                  <li><i className="lni lni-checkmark-circle"></i> To put one photo on top of another, long press the photo</li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Update Moodboard Color</h4>
                              <img src="./assets/images/moodboardColor.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>You can update the moodboard color by pressing the color icon and adjusting via the color wheel.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                      <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                          <div className="table-head">
                              <h4 className="title">Save and Edit</h4>
                              <img src="./assets/images/moodboardNewColor.png" alt="#" style={{ width: '100%', marginBottom: '16px' }}/>
                              <p>When you have made all your changes remember to save. Once saved, the moodboard is "locked". You can unlock it by pressing "Edit".</p>
                          </div>
                      </div>
                  </div>


                  {/*
                      <div className="col-lg-3 col-md-6 col-12">
                          <div className="single-table wow fadeInUp" data-wow-delay=".4s">
                              <div className="table-head">
                                  <h4 className="title">Freelancer</h4>
                                  <p>All the basics for starting a new business</p>
                                  <div className="price">
                                      <h2 className="amount">$24<span className="duration">/mo</span></h2>
                                  </div>
                                  <div className="button">
                                      <a href="javascript:void(0)" className="btn">Buy Freelancer</a>
                                  </div>
                              </div>
                              <div className="table-content">
                                  <h4 className="middle-title">What's Included</h4>
                                  <ul className="table-list">
                                      <li><i className="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                                      <li><i className="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                                      <li><i className="lni lni-checkmark-circle"></i> Morbi leo risus.</li>
                                      <li><i className="lni lni-checkmark-circle"></i> Potenti felis, in cras ligula.</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                    */}


              </div>
          </div>
      </section>
      {/*/ End Pricing Table Area */}

      {/* Start Call To Action Area */}
      <section className="section call-action" id="contact">
          <div className="container">
              <div className="row">
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                      <div className="cta-content">
                          <h2 className="wow fadeInUp" data-wow-delay=".2s">Contact Us With Any Concerns or Questions
                          </h2>
                          <h2 className="wow fadeInUp" data-wow-delay=".2s">admin@renovatebyhbh.com
                          </h2>
                          <p className="wow fadeInUp" data-wow-delay=".4s">Please email us at the address above. We will do our best to respond quickly. Thank you!</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {/* End Call To Action Area */}

      {/* Start Footer Area */}
      <footer className="footer">
          {/* Start Footer Top */}
          <div className="footer-top">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                          {/* Single Widget */}
                          <div className="single-footer f-about">
                              <div className="logo">
                                  <a href="#home" style={{ padding: '4px', background: '#fff', borderRadius: '4px' }}>
                                      <img src="./assets/images/logo/logo.png" alt="#" style={{ width: '45px', height: '45px' }}/>
                                  </a>
                              </div>
                              <p>Stop Wait and Start Creating</p>
                              <ul className="social">
                                  <li><a href="javascript:void(0)"><i className="lni lni-facebook-filled"></i></a></li>
                                  <li><a href="javascript:void(0)"><i className="lni lni-twitter-original"></i></a></li>
                                  <li><a href="javascript:void(0)"><i className="lni lni-instagram"></i></a></li>
                                  <li><a href="javascript:void(0)"><i className="lni lni-linkedin-original"></i></a></li>
                                  <li><a href="javascript:void(0)"><i className="lni lni-youtube"></i></a></li>
                                  <li><a href="javascript:void(0)"><i className="lni lni-pinterest"></i></a></li>
                              </ul>
                              <p className="copyright-text">Designed and Developed by Scott Gourley
                              </p>
                          </div>
                          {/* End Single Widget */}
                      </div>
                      <div className="col-lg-8 col-md-8 col-12">
                          <div className="row">
                            {/*
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="single-footer f-link">
                                        <h3>Solutions</h3>
                                        <ul>
                                            <li><a href="javascript:void(0)">Marketing</a></li>
                                            <li><a href="javascript:void(0)">Analytics</a></li>
                                            <li><a href="javascript:void(0)">Commerce</a></li>
                                            <li><a href="javascript:void(0)">Insights</a></li>
                                            <li><a href="javascript:void(0)">Promotion</a></li>
                                        </ul>
                                    </div>
                                </div>
                            */}

                              <div className="col-lg-3 col-md-6 col-12">
                                  {/* Single Widget */}
                                  <div className="single-footer f-link">
                                      <h3>Support</h3>
                                      <ul>

                                          <li><a href="#guides">Guides</a></li>

                                      </ul>
                                  </div>
                                  {/* End Single Widget */}
                              </div>
                              <div className="col-lg-3 col-md-6 col-12">
                                  {/* Single Widget */}
                                  <div className="single-footer f-link">
                                      <h3>Company</h3>
                                      <ul>
                                          <li><a href="#contact">Contact Us</a></li>
                                      </ul>
                                  </div>
                                  {/* End Single Widget */}
                              </div>
                              <div className="col-lg-3 col-md-6 col-12">
                                  {/* Single Widget */}
                                  <div className="single-footer f-link">
                                      <h3>Legal</h3>
                                      <ul>
                                          <li><a href="/fe/termsandconditions">Terms & Conditions</a></li>
                                          <li><a href="/fe/privacypolicy">Privacy Policy</a></li>
                                      </ul>
                                  </div>
                                  {/* End Single Widget */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/*/ End Footer Top */}
      </footer>
      {/*/ End Footer Area */}

      {/* ========================= scroll-top ========================= */}
      <a href="#" className="scroll-top">
          <i className="lni lni-chevron-up"></i>
      </a>

      {/* ========================= JS here ========================= */}


      </body>
  )
}
