export const getLargestUiOrder = a => {
    let largest = 0;
    a.forEach(o => {
        if (o.ui_order > largest) {
            largest = o.ui_order;
        }
    });

    return largest + 1;
}
