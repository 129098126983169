import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
    verifyToken,
    updateLoginData
} from './components/Login/loginActions';

import Projects from './components/Projects';
import Home from './components/Home';
import Create from './components/Projects/Create';
import Get from './components/Projects/Get';
import GetAll from './components/Projects/GetAll';
import Update from './components/Projects/Update';
import Delete from './components/Projects/Delete';
import PrivacyPolicy from './components/PrivacyPolicy';
import TandC from './components/TandC';

const AppRoutes = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        if (userId && userId !== 'null' && token && token !== 'null') {
            dispatch(verifyToken(userId, token));
        } else {
            dispatch(updateLoginData({ authenticating: false }));
            localStorage.removeItem('userId');
            localStorage.removeItem('token');
        }
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/fe" element={<Home />} />
                <Route path="/fe/projects" element={<Projects />}>
                    <Route path="create" element={<Create />} />
                    <Route path="get" element={<Get />} />
                    <Route index element={<GetAll />} />
                    <Route path="update/:projectId" element={<Update />} />
                    <Route path="delete" element={<Delete />} />
                </Route>
                <Route path="/fe/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/fe/termsandconditions" element={<TandC />} />
            </Routes>
        </Router>
  );
}

export default AppRoutes;
