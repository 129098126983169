import './Projects.css';

const Delete = () => {
    return (
        <div className="delete">
            Delete
        </div>
  );
}

export default Delete;
