import {
    SIGN_IN,
    VERIFY_TOKEN,
    UPDATE_LOGIN_DATA,
    SET_AUTH_ERROR,
    UPDATE_PASSWORD
} from './loginActions';

const initialState = {
    signedIn: false,
    userId: null,
    user: {},
    token: null,
    authenticating: true,
    authError: ''
};

const projectData = (state = initialState, action) => {
    switch (action.type) {
        case `${VERIFY_TOKEN}_PENDING`:
        case `${SIGN_IN}_PENDING`: {
            return {
                ...state,
                authenticating: true,
                authError: ''
            }
        }
        case `${VERIFY_TOKEN}_FULFILLED`:
        case `${SIGN_IN}_FULFILLED`: {
            const token = action?.payload?.data?.token;
            const user = action?.payload?.data?.user;
            const userId = user?.id || action?.payload?.data?.userId;
            const signedIn = Boolean(token && userId);
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);

            return {
                ...state,
                signedIn,
                userId,
                user,
                token,
                authenticating: false,
                authError: ''
            }
        }
        case `${VERIFY_TOKEN}_REJECTED`:
        case `${SIGN_IN}_REJECTED`: {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');

            return {
                ...state,
                ...initialState,
                authenticating: false,
                authError: 'There was an issue loggin you in with the provided credentials. Please try again, or try a different login method. If the issue persists contact Scott'
            }
        }

        case UPDATE_LOGIN_DATA: {
            return {
                ...state,
                ...action.payload
            }
        }

        case SET_AUTH_ERROR: {
            return {
                ...state,
                authError: action.payload
            }
        }

        case `${UPDATE_PASSWORD}_PENDING`: {
            return {
                ...state,
                authError: ''
            }
        }
        case `${UPDATE_PASSWORD}_FULFILLED`: {
            return {
                ...state,
                authError: ''
            }
        }
        case `${UPDATE_PASSWORD}_REJECTED`: {
            return {
                ...state,
                authError: 'There was an issue updating your password. Please try again, or try a different login method. If the issue persists contact Scott'
            }
        }

        default:
            return state
    }
}

export default projectData;
