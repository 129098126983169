import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './Projects.css';

const Get = () => {
    const [project, setProject] = useState(null);
    const [projectId, setProjectId] = useState('');

    const search = async () => {
        try {
            const res = await axios({
                method: 'GET',
                url: `http://localhost:3000/projects/read/${projectId}`
            });
            console.log(res);

            setProject(res?.data);
        } catch (e) {
            alert('ERROR: ', e?.message);
        }
    }
    return (
        <div className="get">
            Enter project ID
            <input value={projectId} onChange={e => setProjectId(e?.target?.value)} />
            <button onClick={search}>
                Search
            </button>
        </div>
  );
}

export default Get;
