import {
    CREATE_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    UPDATE_PROJECT_DATA,
    GET_ALL_PROJECTS,
    GET_PROJECT,
    GET_ALL_PROJECT_DATA,
    CREATE_SECTION,
    CREATE_TASK,
    UPDATE_SECTION,
    UPDATE_TASK,
    DELETE_SECTION,
    DELETE_TASK
} from './projectsActions';

const initialState = {
    pendingProject: false,
    projectError: false,
    projects: [],
    getProjectsPending: false,
    getProjectError: false,
    getProjectPending: false,
    selectedProject: null,
    sections: [],
    sectionPending: false,
    sectionError: false,
    taskPending: false,
    taskError: false
};

const projectData = (state = initialState, action) => {
    switch (action.type) {
        case `${CREATE_PROJECT}_PENDING`: {
            return {
                ...state,
                pendingProject: true,
                projectError: false
            }
        }
        case `${CREATE_PROJECT}_FULFILLED`: {
            return {
                ...state,
                projectError: false
            }
        }
        case `${CREATE_PROJECT}_REJECTED`: {
            return {
                ...state,
                pendingProject: false,
                projectError: true
            }
        }
        case `${UPDATE_PROJECT}_PENDING`: {
            return {
                ...state,
                pendingProject: true,
                projectError: false
            }
        }
        case `${UPDATE_PROJECT}_FULFILLED`: {
            return {
                ...state,
                projectError: false
            }
        }
        case `${UPDATE_PROJECT}_REJECTED`: {
            return {
                ...state,
                pendingProject: false,
                projectError: true
            }
        }
        case `${DELETE_PROJECT}_PENDING`: {
            return {
                ...state,
                pendingProject: true,
                projectError: false
            }
        }
        case `${DELETE_PROJECT}_FULFILLED`: {
            return {
                ...state,
                pendingProject: false,
                projectError: false
            }
        }
        case `${DELETE_PROJECT}_REJECTED`: {
            return {
                ...state,
                pendingProject: false,
                projectError: true
            }
        }
        case `${GET_ALL_PROJECTS}_PENDING`: {
            return {
                ...state,
                getProjectsPending: true,
                getProjectsError: false
            }
        }
        case `${GET_ALL_PROJECTS}_FULFILLED`: {
            return {
                ...state,
                getProjectsPending: false,
                getProjectsError: false,
                projects: action?.payload?.data || []
            }
        }
        case `${GET_ALL_PROJECTS}_REJECTED`: {
            return {
                ...state,
                getProjectsPending: false,
                getProjectsError: true
            }
        }
        case `${GET_PROJECT}_PENDING`: {
            return {
                ...state,
                getProjectError: false,
                getProjectPending: true
            }
        }
        case `${GET_PROJECT}_FULFILLED`: {
            return {
                ...state,
                selectedProject: action?.payload?.data || {},
                getProjectError: false,
                getProjectPending: false
            }
        }
        case `${GET_PROJECT}_REJECTED`: {
            return {
                ...state,
                getProjectError: true,
                getProjectPending: false
            }
        }
        case `${GET_ALL_PROJECT_DATA}_PENDING`: {
            return {
                ...state,
                getAllProjectDataError: false,
                getAllProjectDataPending: true
            }
        }
        case `${GET_ALL_PROJECT_DATA}_FULFILLED`: {
            return {
                ...state,
                sections: action?.payload || [],
                getAllProjectDataError: false,
                getAllProjectDataPending: false
            }
        }
        case `${GET_ALL_PROJECT_DATA}_REJECTED`: {
            return {
                ...state,
                getAllProjectDataError: true,
                getAllProjectDataPending: false
            }
        }
        case `${CREATE_SECTION}_PENDING`:
        case `${UPDATE_SECTION}_PENDING`:
        case `${DELETE_SECTION}_PENDING`: {
            return {
                ...state,
                sectionPending: true,
                sectionError: false
            }
        }
        case `${CREATE_SECTION}_FULFILLED`:
        case `${UPDATE_SECTION}_FULFILLED`:
        case `${DELETE_SECTION}_FULFILLED`: {
            return {
                ...state,
                sectionPending: false,
                sectionError: false
            }
        }
        case `${CREATE_SECTION}_REJECTED`:
        case `${UPDATE_SECTION}_REJECTED`:
        case `${DELETE_SECTION}_REJECTED`: {
            return {
                ...state,
                sectionPending: false,
                sectionError: true
            }
        }
        case `${CREATE_TASK}_PENDING`:
        case `${UPDATE_TASK}_PENDING`:
        case `${DELETE_TASK}_PENDING`: {
            return {
                ...state,
                taskPending: true,
                taskError: false
            }
        }
        case `${CREATE_TASK}_FULFILLED`:
        case `${UPDATE_TASK}_FULFILLED`:
        case `${DELETE_TASK}_FULFILLED`: {
            return {
                ...state,
                taskPending: false,
                taskError: false
            }
        }
        case `${CREATE_TASK}_REJECTED`:
        case `${UPDATE_TASK}_REJECTED`:
        case `${DELETE_TASK}_REJECTED`: {
            return {
                ...state,
                taskPending: false,
                taskError: true
            }
        }
        case UPDATE_PROJECT_DATA: {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state
    }
}

export default projectData;
