import axios from 'axios';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const createProject = (projectData, navigate) => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        const data = {
            ...projectData,
            user_id: state?.loginData?.userId
        }

        return dispatch({
            type: CREATE_PROJECT,
            payload: axios({
                method: 'POST',
                url: '/projects/create',
                data,
                timeout: Object.keys(data?.imageObject || {})?.length ? 15000 : 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            navigate('/fe/projects');
            dispatch(updateProjectData({ pendingProject: false }));
        })
        // .catch(e => {
            // Sentry.Native.captureException(e);
        // })
    }
};

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const updateProject = (projectData, navigate) => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        const data = {
            ...projectData,
        }

        return dispatch({
            type: UPDATE_PROJECT,
            payload: axios({
                method: 'PUT',
                url: `/projects/update/${data?.id}`,
                data,
                timeout: Object.keys(data?.imageObject || {})?.length ? 15000 : 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            navigate('/fe/projects');
            dispatch(updateProjectData({ pendingProject: false }));
        })
        // .catch(e => {
            // Sentry.Native.captureException(e);
        // })
    }
};

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const deleteProject = idToDelete => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: DELETE_PROJECT,
            payload: axios({
                method: 'DELETE',
                url: `/projects/delete/${idToDelete}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        // .then(() => {
        //     dispatch(getAllProjects(state?.loginData?.userId));
        // })
        // .catch(e => {
            // Sentry.Native.captureException(e);
        // })
    }
};

export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const getAllProjects = userId => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: GET_ALL_PROJECTS,
            payload: axios({
                method: 'GET',
                url: `/projects/readAll/${userId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        // .catch(e => {
            // Sentry.Native.captureException(e);
        // })
    }
};

export const GET_ALL_EXPENSES = 'GET_ALL_EXPENSES';
export const getAllExpenses = () => {
    return (dispatch, getState) => {
        const state = getState();
        const projectId = state?.projectsData?.selectedProject?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: GET_ALL_EXPENSES,
            payload: axios({
                method: 'GET',
                url: `/expenses/readAll/${projectId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const GET_MATERIALS = 'GET_MATERIALS';
export const getMaterials = isAfterUpdate => {
    return (dispatch, getState) => {
        const state = getState();
        const projectId = state?.projectsData?.selectedProject?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: GET_MATERIALS,
            payload: axios({
                method: 'GET',
                url: `/materials/readAll/${projectId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            }),
            meta: {
                isAfterUpdate
            }
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const GET_SECTIONS = 'GET_SECTIONS';
export const getSections = () => {
    return (dispatch, getState) => {
        const state = getState();
        const projectId = state?.projectsData?.selectedProject?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: GET_SECTIONS,
            payload: axios({
                method: 'GET',
                url: `/taskSections/readAll/${projectId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const GET_TASKS = 'GET_TASKS';
export const getTasks = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const projectId = state?.projectsData?.selectedProject?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: GET_TASKS,
            payload: axios({
                method: 'GET',
                url: `/taskItems/readAll/${projectId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const GET_ALL_PROJECT_DATA = 'GET_ALL_PROJECT_DATA';
export const getAllProjectData = (task, updateTaskObject) => {
    return (dispatch, getState) => {
        const state = getState();
        const projectId = state?.projectsData?.selectedProject?.id;

        return dispatch({
            type: GET_ALL_PROJECT_DATA,
            payload: new Promise(async (resolve, reject) => {
                try {
                    if (task) {
                        const sectionsCopy = [...(state?.projectsData?.sections || [])];
                        const foundIndex = sectionsCopy.findIndex(v => v.id == task?.section_id);
                        sectionsCopy[foundIndex]?.tasks?.push(task);
                        resolve(sectionsCopy);
                    } else if (updateTaskObject) {
                        let {
                            section_id,
                            increasing,
                            ui_order,
                            originalUiOrder,
                            task_id
                        } = updateTaskObject;
                        const sectionsCopy = [...(state?.projectsData?.sections || [])];
                        const foundIndex = sectionsCopy.findIndex(v => v.id == section_id);
                        let tasksList = [...sectionsCopy[foundIndex]?.tasks];
                        if (increasing) {
                            tasksList = tasksList.map(v => {
                                if (v?.ui_order <= ui_order && v?.ui_order > originalUiOrder) {
                                    v.ui_order -= 1;
                                }
                                if (task_id === v?.id) {
                                    v.ui_order = ui_order;
                                }

                                return v;
                            });
                        } else {
                            tasksList = tasksList.map(v => {
                                if (v?.ui_order >= ui_order && v?.ui_order < originalUiOrder) {
                                    v.ui_order += 1;
                                }
                                if (task_id === v?.id) {
                                    v.ui_order = ui_order;
                                }

                                return v;
                            });
                        }
                        sectionsCopy[foundIndex].tasks = tasksList;
                        sectionsCopy[foundIndex].tasks.sort((a, b) => {
                            return a.ui_order - b.ui_order
                        });

                        resolve(sectionsCopy);
                    } else {
                        const responses = await Promise.all([dispatch(getSections()), dispatch(getTasks())]);
                        const sections = responses?.[0]?.value?.data || [];
                        const tasks = responses?.[1]?.value?.data || [];

                        const origanized = sections.map(section => {
                            section.tasks = tasks.filter(task => task?.section_id === section?.id);

                            return section;
                        });

                        resolve(origanized);
                    }
                } catch (e) {
                    reject(e);
                }
            })
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const GET_MOOD_IMAGES = 'GET_MOOD_IMAGES';
export const getMoodImages = () => {
    return (dispatch, getState) => {
        const state = getState();
        const project_id = state?.projectsData?.selectedProject?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: GET_MOOD_IMAGES,
            payload: axios({
                method: 'GET',
                url: `/moodboardImages/readAll/${project_id}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const GET_PROJECT = 'GET_PROJECT';
export const getProject = projectId => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: GET_PROJECT,
            payload: axios({
                method: 'GET',
                url: `/projects/read/${projectId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getAllProjectData());
            dispatch(getAllExpenses());
            dispatch(getMaterials());
            dispatch(getMoodImages());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const UPDATE_PROJECT_DATA = 'UPDATE_PROJECT_DATA';
export const updateProjectData = payload => {
    return {
        type: UPDATE_PROJECT_DATA,
        payload
    }
};

export const CREATE_SECTION = 'CREATE_SECTION';
export const createSection = section => {
    return (dispatch, getState) => {
        const state = getState();
        const project_id = state?.projectsData?.selectedProject?.id;
        const sections = state?.projectsData?.sections || [];
        const token = state?.loginData?.token;

        return dispatch({
            type: CREATE_SECTION,
            payload: axios({
                method: 'POST',
                url: '/taskSections/create',
                timeout: 5000,
                data: {
                    ...section,
                    project_id,
                    ui_order: sections?.length - 1
                },
                headers: {
                    'X-Auth-Token': token
                }
            }),
            meta: {
                replacementId: section?.replacementId
            }
        })
        .then(() => {
            dispatch(getAllProjectData());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const DELETE_SECTION = 'DELETE_SECTION';
export const deleteSection = sectionId => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: DELETE_SECTION,
            payload: axios({
                method: 'DELETE',
                url: `/taskSections/delete/${sectionId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getAllProjectData());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const UPDATE_SECTION = 'UPDATE_SECTION';
export const updateSection = newSectionData => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: UPDATE_SECTION,
            payload: axios({
                method: 'PUT',
                url: `/taskSections/update/${newSectionData?.id}`,
                timeout: 5000,
                data: {
                    name: newSectionData?.name,
                    ui_order: newSectionData?.ui_order
                },
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getAllProjectData());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const CREATE_TASK = 'CREATE_TASK';
export const createTask = taskData => {
    return (dispatch, getState) => {
        const state = getState();
        const project_id = state?.projectsData?.selectedProject?.id;
        const user_id = state?.loginData?.userId;
        const token = state?.loginData?.token;

        return dispatch({
            type: CREATE_TASK,
            payload: new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/taskItems/create',
                    timeout: Object.keys(taskData?.videoObject || {})?.length ? 120000 : Object.keys(taskData?.imageObject || {})?.length ? 15000 : 5000,
                    data: {
                        ...taskData,
                        project_id,
                        user_id
                    },
                    headers: {
                        'X-Auth-Token': token
                    }
                })
                .then(async res => {
                    try {
                        const task = res?.data || {};
                        await dispatch(getAllProjectData(task));
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                })
                .catch(e => {
                    reject(e);
                })
            })
        })
        .then(() => {
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const UPDATE_TASK = 'UPDATE_TASK';
export const updateTask = taskData => {
    return (dispatch, getState) => {
        const state = getState();
        const taskId = taskData?.id;
        const token = state?.loginData?.token;
        const swapIndex = taskData?.swapIndex;
        const newUiOrder = taskData?.newUiOrder
        if (swapIndex || swapIndex == 0) {
            const sectionsCopy = [...state?.projectsData?.sections];
            const i = sectionsCopy.findIndex(s => taskData?.section_id == s?.id);
            const tasksCopy = [...sectionsCopy[i].tasks];
            const swapTask = tasksCopy?.[swapIndex];

            const promises = [
                axios({
                    method: 'PUT',
                    url: `/taskItems/update/${taskId}`,
                    timeout: Object.keys(taskData?.videoObject || {})?.length ? 120000 : Object.keys(taskData?.imageObject || {})?.length ? 15000 : 5000,
                    data: {
                        ...taskData,
                        ui_order: newUiOrder || swapTask?.ui_order,
                        orderUpdate: true,
                        originalUiOrder: taskData?.ui_order
                    },
                    headers: {
                        'X-Auth-Token': token
                    }
                })
            ];

            return dispatch({
                type: UPDATE_TASK,
                payload: Promise.all(promises),
                meta: {
                    hideLoader: false
                }
            })
            .then(() => {
                const o = {
                    section_id: taskData?.section_id,
                    increasing: taskData?.increasing,
                    ui_order: swapTask?.ui_order,
                    originalUiOrder: taskData?.ui_order,
                    task_id: taskData?.id
                }
                dispatch(getAllProjectData(null, o));
            })
            .catch(e => {
                // Sentry.Native.captureException(e);
            })
        } else {
            return dispatch({
                type: UPDATE_TASK,
                payload: axios({
                    method: 'PUT',
                    url: `/taskItems/update/${taskId}`,
                    timeout: Object.keys(taskData?.videoObject || {})?.length ? 120000 : Object.keys(taskData?.imageObject || {})?.length ? 15000 : 5000,
                    data: {
                        ...taskData
                    },
                    headers: {
                        'X-Auth-Token': token
                    }
                })
            })
            .then(() => {
                const id = state?.projectsData?.selectedProject?.id;
                if (id || id === 0) {
                    dispatch(getAllProjectData());
                }
            })
            .catch(e => {
                // Sentry.Native.captureException(e);
            })
        }
    }
};

export const DELETE_TASK = 'DELETE_TASK';
export const deleteTask = taskData => {
    return (dispatch, getState) => {
        const state = getState();
        const {
            id: taskId,
            section_id: sectionId,
            ui_order: uiOrder
        } = taskData;
        const token = state?.loginData?.token;

        return dispatch({
            type: DELETE_TASK,
            payload: axios({
                method: 'DELETE',
                url: `/taskItems/delete/${taskId}?sectionId=${sectionId}&uiOrder=${uiOrder}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getAllProjectData());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const GET_EXPENSE = 'GET_EXPENSE';
export const getExpense = () => {
    return (dispatch, getState) => {
        const state = getState();
        const expenseId = state?.projectsData?.selectedExpense?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: GET_EXPENSE,
            payload: axios({
                method: 'GET',
                url: `/expenses/read/${expenseId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const CREATE_EXPENSE = 'CREATE_EXPENSE';
export const createExpense = expenseData => {
    return (dispatch, getState) => {
        const state = getState();
        const project_id = state?.projectsData?.selectedProject?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: CREATE_EXPENSE,
            payload: axios({
                method: 'POST',
                url: '/expenses/create',
                timeout: Object.keys(expenseData?.imageObject || {})?.length ? 15000 : 5000,
                data: {
                    project_id,
                    ...expenseData
                },
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getAllExpenses());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export const updateExpense = expenseData => {
    return (dispatch, getState) => {
        const state = getState();
        const expenseId = state?.projectsData?.selectedExpense?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: UPDATE_EXPENSE,
            payload: axios({
                method: 'PUT',
                url: `/expenses/update/${expenseId}`,
                timeout: Object.keys(expenseData?.imageObject || {})?.length ? 15000 : 5000,
                data: expenseData,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getAllExpenses());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const deleteExpense = () => {
    return (dispatch, getState) => {
        const state = getState();
        const expenseId = state?.projectsData?.selectedExpense?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: DELETE_EXPENSE,
            payload: axios({
                method: 'DELETE',
                url: `/expenses/delete/${expenseId}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getAllExpenses());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const CREATE_MATERIAL = 'CREATE_MATERIAL';
export const createMaterial = materialData => {
    return (dispatch, getState) => {
        const state = getState();
        const project_id = state?.projectsData?.selectedProject?.id;
        const token = state?.loginData?.token;

        return dispatch({
            type: CREATE_MATERIAL,
            payload: axios({
                method: 'POST',
                url: '/materials/create',
                timeout: 5000,
                data: {
                    ...materialData,
                    project_id,
                },
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getMaterials());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const updateMaterial = materialData => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: UPDATE_MATERIAL,
            payload: axios({
                method: 'PUT',
                url: `/materials/update/${materialData?.id}`,
                timeout: 5000,
                data: {
                    ...materialData
                },
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getMaterials(/* is after update */true));
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const DELETE_MATERIAL = 'DELETE_MATERIAL';
export const deleteMaterial = idToDelete => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: DELETE_MATERIAL,
            payload: axios({
                method: 'DELETE',
                url: `/materials/delete/${idToDelete}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getMaterials());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const CREATE_MOOD_IMAGE = 'CREATE_MOOD_IMAGE';
export const createMoodImage = moodImageData => {
    return (dispatch, getState) => {
        const state = getState();
        const project_id = state?.projectsData?.selectedProject?.id;

        const token = state?.loginData?.token;

        return dispatch({
            type: CREATE_MOOD_IMAGE,
            payload: axios({
                method: 'POST',
                url: '/moodboardImages/create',
                timeout: 15000,
                data: {
                    ...moodImageData,
                    project_id
                },
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getMoodImages());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const UPDATE_MOOD_IMAGE = 'UPDATE_MOOD_IMAGE';
export const updateMoodImage = moodImageData => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: UPDATE_MOOD_IMAGE,
            payload: axios({
                method: 'PUT',
                url: `/moodboardImages/update/${moodImageData?.id}`,
                timeout: 15000,
                data: {
                    ...moodImageData
                },
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(() => {
            dispatch(getMoodImages());
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const UPDATE_ALL_MOOD_IMAGES = 'UPDATE_ALL_MOOD_IMAGES';
export const updateAllMoodImages = (fromDelete, moodboardColor) => {
    return (dispatch, getState) => {
        const state = getState();
        const moodboardImagesToSave = state?.projectsData?.moodboardImagesToSave || {};
        const moodboardImagesToSaveArray = Object.values(moodboardImagesToSave);
        const token = state?.loginData?.token;

        if (moodboardImagesToSaveArray?.length) {
            return dispatch({
                type: UPDATE_ALL_MOOD_IMAGES,
                payload: axios({
                    method: 'PUT',
                    url: '/moodboardImages/updateAll',
                    timeout: 15000,
                    data: moodboardImagesToSaveArray,
                    headers: {
                        'X-Auth-Token': token
                    }
                })
            })
            .then(() => {
                if (fromDelete) {
                    dispatch(updateProjectData({
                        moodboardEditEnabled: true
                    }));
                }
                dispatch(getMoodImages());
            })
            .catch(e => {
                // Sentry.Native.captureException(e);
            })
        } else {
            dispatch(getMoodImages());
        }
    }
};

export const DELETE_MOOD_IMAGE = 'DELETE_MOOD_IMAGE';
export const deleteMoodImage = idToDelete => {
    return (dispatch, getState) => {
        const state = getState();
        const token = state?.loginData?.token;

        return dispatch({
            type: DELETE_MOOD_IMAGE,
            payload: axios({
                method: 'DELETE',
                url: `/moodboardImages/delete/${idToDelete}`,
                timeout: 5000,
                headers: {
                    'X-Auth-Token': token
                }
            })
        })
        .then(async () => {
            // dispatch(updateAllMoodImages(true));
            try {
                await dispatch(getMoodImages());
                dispatch(updateProjectData({
                    selectedSubcat: 'materials'
                }));
                dispatch(updateProjectData({
                    selectedSubcat: 'moodboard',
                    moodboardImagesPending: false
                }));
            } catch (e) {
                dispatch(updateProjectData({
                    selectedSubcat: 'materials'
                }));
                dispatch(updateProjectData({
                    selectedSubcat: 'moodboard',
                    moodboardImagesPending: false
                }));
            }
        })
        .catch(e => {
            // Sentry.Native.captureException(e);
        })
    }
};

export const MOVE_TASKS = 'MOVE_TASKS';
export const moveTasks = payload => {
    return {
        type: MOVE_TASKS,
        payload
    }
};
