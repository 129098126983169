import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import Box from '@mui/material/Box';

import './Login.css';

import {
    signIn,
    signInWithGoogle,
    forgotPassword,
    updatePassword
} from './loginActions';

const Login = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showResetCodeModal, updateShowResetCodeModal] = useState(false);
    const [forgetPasswordEmail, setForgetPasswordEmail] = useState('');
    const [showResetModal, updateShowResetModal] = useState(false);

    const [resetEmail, setResetEmail] = useState('');
    const [resetPassword, setResetPassword] = useState('');
    const [resetCode, setResetCode] = useState('');

    const signedIn = useSelector(state => state?.loginData?.signedIn);
    const authError = useSelector(state => state?.loginData?.authError);

    useEffect(() => {
        if (authError) {
            updateShowResetCodeModal(false);
            updateShowResetModal(false);
        }
    }, [authError]);

    const navigate = useNavigate();

    const handleEmailSignIn = () => {
        dispatch(signIn({
            email,
            password
        }));
    }

    const handleForgotPassword = () => {
        updateShowResetCodeModal(true);
    }

    const handlePasswordReset = async () => {
        await dispatch(forgotPassword(forgetPasswordEmail));
        updateShowResetCodeModal(false);
        updateShowResetModal(true);
        setResetEmail(forgetPasswordEmail);
    }

    const handleUpdatePassword = async () => {
        await dispatch(updatePassword(
            resetEmail,
            resetPassword,
            resetCode
        ));
        updateShowResetModal(false);
        setResetEmail('');
        setResetPassword('');
        setResetCode('');
    }

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            await dispatch(signInWithGoogle(tokenResponse?.access_token));
        }
    });

    return (
        <Box className="main-container">
            <Box className="login-container">
                <TextField className="margin-bottom" label="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                <TextField className="margin-bottom" label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                <Button className="margin-bottom" variant="contained" onClick={handleEmailSignIn}>Sign In</Button>
                <Button className="margin-bottom" onClick={handleForgotPassword}>Forgot password</Button>
                <Box className="margin-bottom">
                    -OR-
                </Box>
                <Button className="margin-bottom" variant="contained"
                    onClick={login}
                >
                    Google Sign In
                </Button>
                {authError &&
                    <Box className="login-error">
                        {authError}
                    </Box>
                }
            </Box>
            <Modal
                isOpen={showResetCodeModal}
                onAfterOpen={() => {}}
                onRequestClose={() => {
                    updateShowResetCodeModal(false);
                    setForgetPasswordEmail('');
                }}
                className="my-modal"
                contentLabel="Forgot Password Modal"
                style={{
                    overlay: {
                        zIndex: 1
                    }
                }}
            >
                <TextField
                    label="Email"
                    placeholder=""
                    value={forgetPasswordEmail}
                    onChange={e => setForgetPasswordEmail(e.target.value)}
                    className="margin-bottom"
                />
                <Button variant="contained" onClick={handlePasswordReset}>
                    Send reset code
                </Button>
            </Modal>
            <Modal
                isOpen={showResetModal}
                onAfterOpen={() => {}}
                onRequestClose={() => {
                    updateShowResetModal(false);
                    setResetEmail('');
                    setResetPassword('');
                    setResetCode('');
                }}
                className="my-modal"
                contentLabel="Reset Password Modal"
                style={{
                    overlay: {
                        zIndex: 1
                    }
                }}
            >
                <h4 className="margin-bottom">Enter your email, new password, and the reset code emailed to you</h4>
                <TextField
                    label="Email"
                    placeholder=""
                    value={resetEmail}
                    onChange={e => setResetEmail(e.target.value)}
                    className="margin-bottom"
                />
                <TextField
                    label="Password"
                    placeholder=""
                    value={resetPassword}
                    onChange={e => setResetPassword(e.target.value)}
                    className="margin-bottom"
                />
                <TextField
                    label="Reset Code"
                    placeholder=""
                    value={resetCode}
                    onChange={e => setResetCode(e.target.value)}
                    className="margin-bottom"
                />
                <Button variant="contained" onClick={handleUpdatePassword}>
                    Update password
                </Button>
            </Modal>
        </Box>
    )
}

export default Login;
