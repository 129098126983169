import axios from 'axios';

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const verifyToken = (userId, token) => {
    return (dispatch, getState) => {
        const state = getState();
        const authToken = state?.authData?.token;

        return dispatch({
            type: VERIFY_TOKEN,
            payload: axios({
                method: 'POST',
                url: '/users/verifyToken',
                data: {
                    userId,
                    token
                },
                timeout: 5000
            })
        })
    }
};

export const SIGN_IN = 'SIGN_IN';
export const signIn = userInfo => {
    return (dispatch, getState) => {
        const state = getState();

        return dispatch({
            type: SIGN_IN,
            payload: axios({
                method: 'POST',
                url: '/users/signIn',
                data: {
                    ...userInfo
                },
                timeout: 5000
            })
        })
    }
};

export const SIGN_IN_WITH_GOOGLE = 'SIGN_IN_WITH_GOOGLE';
export const signInWithGoogle = accessToken => {
    return (dispatch, getState) => {
        return dispatch({
            type: SIGN_IN_WITH_GOOGLE,
            payload: axios({
                method: 'GET',
                url: 'https://www.googleapis.com/oauth2/v2/userinfo',
                timeout: 5000,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
        })
        .then(response => {
            const googleUser = response?.value?.data || {};
            const {
                given_name: firstName,
                family_name: lastName,
                email
            } = googleUser;
            if (firstName && lastName && email) {
                dispatch(signIn({
                    firstName,
                    lastName,
                    email,
                    password: null,
                    googleToken: accessToken
                }));
            } else {
                // TODO HANDLE ERROR
            }
        })
    }
};

export const UPDATE_LOGIN_DATA = 'UPDATE_LOGIN_DATA';
export const updateLoginData = payload => {
    return {
        type: UPDATE_LOGIN_DATA,
        payload
    }
}

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const forgotPassword = toEmail => {
    return (dispatch, getState) => {
        const state = getState();

        return dispatch({
            type: FORGOT_PASSWORD,
            payload: axios({
                method: 'POST',
                url: '/users/forgotPassword',
                data: {
                    toEmail
                },
                timeout: 5000
            })
        })
    }
};

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const updatePassword = (email, password, resetCode) => {
    return (dispatch, getState) => {
        const state = getState();

        return dispatch({
            type: UPDATE_PASSWORD,
            payload: axios({
                method: 'POST',
                url: '/users/updatePassword',
                data: {
                    email,
                    resetCode,
                    password
                },
                timeout: 5000
            })
        })
    }
};

export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const setAuthError = payload => {
    return {
        type: SET_AUTH_ERROR,
        payload
    }
}
