import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createProject } from './projectsActions';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import './Projects.css';

const Create = () => {
    const dispatch = useDispatch();

    const imageRef = useRef();
    const fileRef = useRef();

    const navigate = useNavigate();

    const userId = useSelector(state => state?.loginData?.userId);
    const pendingProject = useSelector(state => state?.projectsData?.pendingProject);

    const [name, setName] = useState('');
    const [budget, setBudget] = useState(null);
    const [color, setColor] = useState('#E07E8B');
    const [photo, setPhoto] = useState({});
    const [shareable, setShareable] = useState(false);
    const [adminTemplate, setAdminTemplate] = useState(false);
    const [imageObject, setImageObject] = useState(null);

    // shareable
    // name
    // cover_photo
    // budget
    // status
    // color
    //
    // expenses:
    //     name
    //     category
    //     color
    //     receipt_image_url
    //     amount
    //     project_id
    // materials:
    //     name
    //     type
    //     checked
    //     link
    //     project_id
    // moodboardImages:
    //     image_url
    //     name
    //     width
    //     height
    //     project_id
    // taskSections:
    //     name
    //     project_id
    //     taskItems:
    //         name
    //         description
    //         checked
    //         video
    //         timestamp
    //         section_id
    //         project_id
    //         photo
    //         date
    //         time
    //         color
    const circleColors = [
        '#E07E8B',
        '#C14C80',
        '#753581',
        '#2D3A64',
        '#DD4E40',
        '#F5D0EF',
        '#9CA4F9',
        '#9BE8A0',
        '#DADBDF'
    ];

    const handlePhotoUpload = () => {
        const file = fileRef.current.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = function(e) {
                const imagePreview = imageRef.current;
                imagePreview.src = e.target.result;
                const base64String = e.target.result.split(',')[1];
                setImageObject({
                    imageBody: base64String,
                    imageExtension: file?.name?.slice(file?.name?.lastIndexOf('.') + 1)
                });
            }

            reader.readAsDataURL(file);
        }
    }

    const handleCreate = () => {
        dispatch(createProject({
            user_id: userId,
            shareable,
            name,
            cover_photo: photo,
            budget,
            color,
            imageObject,
            adminTemplate
        }, navigate));
    }

    const handleAddPhoto = () => {
        fileRef.current.click();
    }

    return (
        <div className="create">
            <section className={`load-screen cover ${pendingProject ? 'show' : ''}`.trim()}>
                <div className="spinner" />
            </section>
            <h1 className="page-title" style={{ marginLeft: 0 }}>Create New Project</h1>
            <TextField
                label="Title"
                placeholder=""
                value={name}
                type="text"
                onChange={e => setName(e.target.value)}
                variant="standard"
                sx={{ mb: 3, width: '100%' }}
            />
            <TextField
                label="Budget"
                placeholder=""
                value={budget}
                type="number"
                onChange={e => setBudget(e.target.value)}
                variant="standard"
                sx={{ width: '100%' }}
            />
            <Box sx={{ maxWidth: '100%' }}>
                <img ref={imageRef} style={{ marginTop: '24px', marginBottom: '12px' }} />
                <div onClick={handleAddPhoto} className="add-media">
                    <AddAPhotoIcon />&nbsp;&nbsp;Add a project cover photo
                </div>
                <input hidden ref={fileRef} type="file" onChange={handlePhotoUpload} />
            </Box>
            <Divider sx={{ width: '100%', mb: 3, mt: 1 }} />
            Choose a color
            <Box
                sx={{ display: 'flex' }}
                className="circles"
            >
                {circleColors.map(c => <div onClick={() => setColor(c)} className="color-circle" style={{ background: c, border: c === color ? '4px solid #000' : ''  }} />)}
            </Box>
            <Divider sx={{ width: '100%', mb: 3, mt: 1 }} />
            <FormControlLabel
                label="Shareable"
                control={
                    <Checkbox
                        placeholder=""
                        value={shareable}
                        type="checkbox"
                        onChange={e => setShareable(e.target.checked)}
                    />
                }
            />
            <Divider sx={{ width: '100%', mb: 3, mt: 0 }} />
            <FormControlLabel
                label="Admin Template"
                control={
                    <Checkbox
                        placeholder=""
                        value={adminTemplate}
                        type="checkbox"
                        onChange={e => setAdminTemplate(e.target.checked)}
                    />
                }
            />
            <Divider sx={{ width: '100%', mb: 3, mt: 0 }} />

            <Button variant="contained" sx={{ alignSelf: 'flex-end' }} onClick={handleCreate}>CREATE</Button>
        </div>
  );
}

export default Create;
