import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Modal from 'react-modal';

import { rootReducer } from './rootReducer';

import Routes from './Routes';

import './App.css';

const store = createStore(rootReducer, applyMiddleware(thunk, promise));

Modal.setAppElement('#root');

const App = () => {

    return (
        <GoogleOAuthProvider clientId="555128691352-g064pm03bepre5g58r6nkrhdd84aega0.apps.googleusercontent.com">
            <Provider store={store}>
                <div className="App">
                    <Routes />
                </div>
            </Provider>
        </GoogleOAuthProvider>
  );
}

export default App;
